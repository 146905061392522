<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.mobile"
    width="600"
    scrollable
    v-model="dialog"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-list-item
        v-bind="attrs"
        v-on="on"
        v-if="post.profile.id == currentUser.selectedProfile.id"
        >
        <v-list-item-icon>
          <v-icon
            color="red"
            >
            mdi-delete
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content
          >
          <v-list-item-title>
            Eliminar
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-card>
      <v-card-title>
        Eliminar publicación
      </v-card-title>
      <v-card-text>
        ¿Está seguro de que desea eliminar la publicación? Esta acción <span class="font-weight-medium red--text">no se puede deshacer</span>.
      </v-card-text>
      <v-card-actions
        class="justify-end"
        >
        <v-btn
          class="mr-2"
          text
          @click="dialog = false"
          >
          Cancelar
        </v-btn>

        <v-btn
          class="mr-2"
          color="error"
          depressed
          @click="deletePost"
          >
          Eliminar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="sass" scoped>
  #images-container
    .v-btn
      opacity: 0.8

  $btn-hover-opacity: 1 !default      
</style>

<script>
import { mapGetters } from 'vuex'
import { DeletePost } from '@/graphql/mutations/posts'

export default {
  data: () => ({
    dialog: false,
  }),

  props: {
    post: {
      type: Object,
      required: true,
    },

    id: {
      type: Number | String,
      required: true
    }
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  methods: {
    deletePost () {
      this.$apollo.mutate({
        mutation: DeletePost,
        variables: {
          input: {
            id: this.id
          }
        }
      }).then( res => {
        this.$emit('reload')
      })
    },
  },
}
</script>
